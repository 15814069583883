import React from 'react';

const WelcomeFile = () => (
    <div className="welcome">
        <h1 className="welcome__title">Welcome to johnmcdonald.dev</h1>
        <h2 className="welcome__subtitle">My Interactive Portfolio Playground</h2>
        <div className="welcome__new">
            <h3>What's New?</h3>
            <p>
                This is my sandbox to try out new tricks and tools across the 
                full stack. It is a place for me to represent my work in addition to 
                practice continuous learning. As a result the features, layout, and 
                functionality of the site will constantly change. This is where you will 
                find a quick overview of the latest updates to check out while browsing 
                my site.
            </p>
            <ul>
                <li>
                    <div>Resizeable Elements</div>
                    <p>
                        This release you will find yourself immersed in the fan favorite Visual Studio
                        Code. While recreating some of the code editor's functionality, I was not happy 
                        with any of the React resize libraries. As a result I decided to write my own
                        which can be found bordering the project panel and the terminal.
                    </p>
                </li>
                <li>
                    <div>React-Spring Animation</div>
                    <p>
                        A library I have been interested in testing out is react-spring, a spring-physics 
                        based animation library. In eliminating duration defined animations, react-spring 
                        allows developers to create more natural, fluid animations. You will find
                        this library used in the project panel dropdowns as well as in the tab bar when
                        rearranging tabs.
                    </p>
                </li>
            </ul>
        </div>
    </div>
);

export default WelcomeFile;