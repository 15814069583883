import React from 'react';

const ReadMeFile = () => (
    <div className="read-me">
        <h1>About</h1>
        <p>
            I am a solution architect and full stack developer with a passion for 
            innovation, problem solving, and continuous learning. I specialize in 
            developing custom, cloud-native solutions designed for scalability and
            maintainability. In my current role at
            <a href="https://www.accenture.com/"> Accenture</a>, I am a technical 
            lead for our experimentation driven development group. To learn more
            about some of my projects please check out the project category files
            in the project panel.
        </p>
        <h2>Skills</h2>
            <div className="read-me__skills">
                <p>Primary Programming Languages:</p>
                <p>JavaScript/TypeScript, Python</p>
            </div>
            <div className="read-me__skills">
                <p>Web Full Stack:</p>
                <p>Node.js, React, Redux, Express.js, GraphQL, WebSockets, Jest</p>
            </div>
            <div className="read-me__skills">
                <p>Internet of Things (IoT):</p>
                <p>MQTT, Raspbian, Amazon Alexa, Dialogflow</p>
            </div>
            <div className="read-me__skills">
                <p>DevOps:</p>
                <p>Git, GitLab CI, TerraForm</p>
            </div>
            <div className="read-me__skills">
                <p>Design:</p>
                <p>Adobe Suite, Sketch, Invision</p>
            </div>
        <h2>Certifications</h2>
        <ul className="read-me__certifications">
            <li>AWS Certified Solutions Architect Associate</li>
            <li>AWS Certified Developer Associate</li>
            <li>Certified ScrumMaster (CSM)</li>
            <li>Certified Scrum Product Owner (CSPO)</li>
        </ul>
    </div>
);

export default ReadMeFile;