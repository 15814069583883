const vscReducerDefaultState = {
    files: [
        {
            file: 'Welcome',
            icon: 'JavaScript',
        },
        {
            file: 'README.md',
            icon: 'ReadMe'
        },
        {
            file: 'Web.jsx',
            icon: 'JSX'
        },
        {
            file: 'NLP.js',
            icon: 'JavaScript'
        },
        {
            file: 'IoT.py',
            icon: 'Python'
        },
        {
            file: 'DevOps.tf',
            icon: 'Terraform'
        },
        {
            file: 'XR.cs',
            icon: 'CSharp'
        }
    ],
    openFiles: [
        {
            file: 'Welcome',
            icon: 'VSCode',
        }
    ],
    activeFile: 'Welcome',
    projectSliderOpened: true,
    terminalOpened: true,
    fileSize: 600
};

const vscReducer = (state = vscReducerDefaultState, action) => {
    switch(action.type) {
        case 'OPEN_FILE': {
            let openFiles;
            if (state.openFiles.filter(({ file }) => file === action.file).length === 0) {
                openFiles = [
                    ...state.openFiles,
                    {
                        file: action.file,
                        icon: action.icon
                    }
                ];
            } else {
                openFiles = [
                    ...state.openFiles
                ];
            }
            return {
                ...state,
                openFiles: openFiles,
                activeFile: action.file
            };
        }
        case 'CLOSE_FILE': {
            let openFiles = state.openFiles.filter(({ file }) => file !== action.file);
            let activeFile;
            if (openFiles.length === 0) {
                openFiles = [{file: 'Welcome', icon: 'JavaScript'}];
                activeFile = 'Welcome';
            } else {
                activeFile = openFiles[openFiles.length - 1].file;
            }
            return {
                ...state,
                openFiles,
                activeFile 
            };
        }
        case 'REORDER_OPEN_FILES': {
            const updatedFilesOrder = action.updatedIndices.map(i => state.openFiles[i]);
            return {
                ...state,
                openFiles: updatedFilesOrder
            };
        }
        case 'SET_ACTIVE_FILE':
            return {
                ...state,
                activeFile: action.file
            };
        case 'TOGGLE_PROJECT_SLIDER':
            return {
                ...state,
                projectSliderOpened: !state.projectSliderOpened
            };
        case 'OPEN_TERMINAL':
            return {
                ...state,
                terminalOpened: true
            };
        case 'CLOSE_TERMINAL': 
            return {
                ...state,
                terminalOpened: false
            };
        case 'SET_FILE_SIZE': 
            return {
                ...state,
                fileSize: action.size
            };
        default:
            return state;
    }
};

export default vscReducer;