import React from 'react';

const IoTFile = () => (
    <div className="iot">
        <h1 className="iot__title">Internet of Things (IoT)</h1>
        <div className="iot__project">
            Developed an IoT solution utilizing Bluetooth Low Energy (BLE) 
            beacons and WiFi probe request packet sniffing to bring e-commerce 
            analytics to physical retail stores.
        </div>
        <div className="iot__project">
            Worked with an automotive client to explore connected city 
            opportunities by leveraging their vehicles’ telemetry data and 
            visualizing the data through a series of real-time dashboards.
        </div>
        <div className="iot__project">
            Created a Smart City service for a client in the resources industry 
            capable of forecasting customers’ utility bills based on historical 
            usage and project weather patterns.
        </div>
        <div className="iot__project">
            Developed two variations of an artificial intelligence bartender 
            including a Raspberry Pi with object detection and Bluetooth locking 
            capabilities and a Raspberry Pi connected to pumps for automatic and 
            controlled pouring, both featuring voice interfaces.
        </div>
    </div>
);

export default IoTFile;