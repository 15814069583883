import React, { useRef } from 'react';
import clamp from 'lodash-es/clamp';
import swap from 'lodash-move';
import { useGesture } from 'react-use-gesture';
import { useSprings, animated, interpolate } from 'react-spring';
import * as FileIcons from '../assets/FileTypeIcons';
import Close from '../assets/Close';

const fn = (order, down, originalIndex, curIndex, x) => index =>
  down && index === originalIndex
    ? { x: curIndex * 160 + x, scale: 1.1, zIndex: '1', shadow: 15, immediate: n => n === 'x' || n === 'zIndex' }
    : { x: order.indexOf(index) * 160, scale: 1, zIndex: '0', shadow: 1, immediate: false }

const DraggableList = ({ 
  items, 
  setActiveFile, 
  closeFile, 
  // reorderFiles, 
  activeFile 
}) => {
  const order = useRef(items.map((_, index) => index));
  const [springs, setSprings] = useSprings(items.length, fn(order.current));
  const bind = useGesture(({ args: [originalIndex], down, delta: [x] }) => {
    const curIndex = order.current.indexOf(originalIndex);
    const curCol = clamp(Math.round((curIndex * 160 + x) / 160), 0, items.length - 1);
    const newOrder = swap(order.current, curIndex, curCol);
    setSprings(fn(newOrder, down, originalIndex, curIndex, x));
    if (!down) {
      order.current = newOrder;
      // reorderFiles();
    }
  });

  return (
    <div className="content" style={{ width: items.length * 160 }}>
      {springs.map(({ zIndex, shadow, x, scale }, i) => {
        const Icon = FileIcons[items[i].icon];
        const className = items[i].file === activeFile ? 'active' : '';
        return (
          <animated.div
            className={`content__file ${className}`}
            {...bind(i)}
            key={i}
            style={{
              zIndex,
              boxShadow: shadow.interpolate(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
              transform: interpolate([x, scale], (x, s) => `translate3d(${x}px,0,0) scale(${s})`)
            }}
          >
            <div className="content__body" onClick={() => setActiveFile(items[i].file)}>
              <Icon className="content__file-icon" />
              {items[i].file}
            </div>
            <div onClick={() => closeFile(items[i].file)}>
              <Close className="content__file-close" />
            </div>
          </animated.div>
        )
      })}
    </div>
  );
};

export default DraggableList;
