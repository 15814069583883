import React from 'react';

const Circle = ({ className }) => (
    <svg viewBox="0 0 12.5 12.5" className={`circle ${className}`}>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <circle cx="6.25" cy="6.25" r="6"/>
            </g>
        </g>
    </svg>
);

export default Circle;