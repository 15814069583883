import React from 'react';
import { connect } from 'react-redux';
import SourceControl from '../assets/SourceControl';
import { openTerminal } from '../actions/vsc';

const VSCFooter = (props) => {
    return (
        <footer className="vsc-footer" >
            <div className="vsc-footer__section left">
                <SourceControl className="source-control-footer" />
                <p>master*</p>
                <p>John McDonald | All Rights Reserved &copy; 2020</p>
            </div>
            <div className="vsc-footer__section right">
                <div onClick={() => props.openTerminal()}>
                    {/* <p>Contact</p> */}
                </div>
                <a href="https://www.linkedin.com/in/john-mcdonald-dev" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            </div>
        </footer>
    );
};

const mapDispatchToProps = (dispatch) => ({
    openTerminal: () => dispatch(openTerminal())
})

export default connect(null, mapDispatchToProps)(VSCFooter);
