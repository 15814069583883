import React from 'react';

const Arrow = ({ className }) => (
    <svg viewBox="0 0 10.98 6.42" className={`arrow ${className}`}>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <rect x="-0.85" y="2.6" width="7.78" height="1.41" rx="0.5" transform="translate(3.23 -1.18) rotate(45)"/>
                <rect x="3.65" y="2.5" width="8.25" height="1.41" rx="0.5" transform="translate(15.54 -0.02) rotate(135)"/>
            </g>
        </g>
    </svg>
);

export default Arrow;