import React from 'react';

const Explorer = ({ className }) => (
    <svg viewBox="0 0 20.8 23.8" className={`explorer ${className}`}>
        <title>Explorer</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <polygon points="13.9 16.9 13.9 21.9 12.9 22.9 1.9 22.9 0.9 21.9 0.9 7.9 1.9 6.9 6.9 6.9 6.9 15.9 7.9 16.9 13.9 16.9"/>
                <path d="M6.9,6.9v-5l1-1h9l3,3v12l-1,1h-5"/>
                <polyline points="20.1 4.9 15.91 4.9 15.9 4.47 15.9 0.9"/>
            </g>
        </g>
    </svg>
);

export default Explorer;