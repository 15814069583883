import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Arrow from '../assets/Arrow';
import Close from '../assets/Close';
import { closeTerminal } from '../actions/vsc';
import { useKeyboardEvent } from '../hooks/useKeyboardEvent';
import { useFocus } from '../hooks/useFocus';

const VSCTerminal = (props) => {
    const [ formValues, setFormValues ] = useState({firstName: '', lastName: '', email: '', message: '', submitForm: ''});
    const [ formValuesShow, setFormValuesShow ] = useState({firstName: true, lastName: false, email: false, message: false, submitForm: false});
    const [ firstNameRef, setFirstNameFocus ] = useFocus();
    const [ lastNameRef, setLastNameFocus ] = useFocus();
    const [ emailRef, setEmailFocus ] = useFocus();
    const [ messageRef, setMessageFocus ] = useFocus();
    const [ submitFormRef, setSubmitFormFocus ] = useFocus();

    useKeyboardEvent('Enter', async () => {
        if (formValues.firstName.trim() === '') {
            setFirstNameFocus();
        } else if (formValues.lastName.trim() === '') {
            setFormValuesShow({
                ...formValuesShow,
                lastName: true
            });
            setLastNameFocus();
        } else if (formValues.email.trim() === '') {
            setFormValuesShow({
                ...formValuesShow,
                email: true
            });
            setEmailFocus();
        } else if (formValues.message.trim() === '') {
            if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formValues.email)){
                setFormValuesShow({
                    ...formValuesShow,
                    message: true
                });
                setMessageFocus();
            } else {
                setFormValues({
                    ...formValues,
                    email: ''
                });
                setEmailFocus();
            }
        } else if (formValues.submitForm.trim() === '') {
            setFormValuesShow({
                ...formValuesShow,
                submitForm: true
            });
            setSubmitFormFocus();
        } else {
            if (formValues.submitForm === 'y') {
                try {
                    await axios.post('https://api.johnmcdonald.dev/v1/contact', JSON.stringify(formValues), { headers: { 'Content-Type': 'application/json' }});
                } catch (err) {
                }
                const resetForm = formValuesShow;
                Object.keys(resetForm).forEach(v => resetForm[v] = false);
                setFormValuesShow({...resetForm});
            } else {
                const resetForm = formValues;
                Object.keys(resetForm).forEach(v => resetForm[v] = '');
                setFormValues({...resetForm});
                const resetFormShow = formValuesShow;
                Object.keys(resetFormShow).forEach(v => resetFormShow[v] = false);
                setFormValuesShow({...resetFormShow, firstName: true});
                setFirstNameFocus();
            }
        }
    });

    const handleInputChange = e => {
        const { name, value } = e.target;
        if (name !== 'submitForm') {
            setFormValues({...formValues, [name]: value});
        } else {
            if (value.length === 1 && (value === 'y' || value === 'n')) {
                setFormValues({...formValues, [name]: value});
            }
        }
    };
    

    return (
        <>
            <div className="vsc-terminal__titlebar">
                <div className="vsc-terminal__titlebar-text">CONTACT</div>
                <div className="vsc-terminal__titlebar-icons">
                    <div >
                        <Arrow className="vsc-terminal__titlebar-arrow" />
                    </div>
                    <div className="vsc-terminal__titlebar-icon-container">  {/* onClick={() => props.closeTerminal()} */}
                        <Close className="vsc-terminal__titlebar-close" />
                    </div>
                </div>
            </div>
            <hr className="vsc-terminal__title-underline" />
            <div className="vsc-terminal__form">
                {
                    formValuesShow.firstName &&
                    <div className="vsc-terminal__form-instructions">To reach out please begin by entering your first name</div>
                }
                {
                    !formValuesShow.firstName &&
                    <div className="vsc-terminal__form-completion">Contact Form Successfully Submitted!</div>
                }
                { 
                    formValuesShow.firstName &&
                    <label>
                        First Name:
                        <input ref={firstNameRef} type="text" onChange={handleInputChange} value={formValues.firstName} name="firstName" autoFocus />
                    </label> 
                }
                {
                    formValuesShow.lastName &&
                    <label>
                        Last Name:
                        <input ref={lastNameRef} type="text" onChange={handleInputChange} value={formValues.lastName} name="lastName" />
                    </label>
                }
                {
                    formValuesShow.email &&
                    <label>
                        Email:
                        <input ref={emailRef} type="text" onChange={handleInputChange} value={formValues.email} name="email" />
                    </label>
                }
                {
                    formValuesShow.message &&
                    <label>
                        Message:
                        <textarea ref={messageRef} type="text" onChange={handleInputChange} value={formValues.message} name="message" />
                    </label>
                }
                {
                    formValuesShow.submitForm &&
                    <label>
                        Would you like to submit this contact form? (yes/no):
                        <input ref={submitFormRef} type="text" onChange={handleInputChange} placeholder="y/n" value={formValues.submitForm} name="submitForm" />
                    </label>
                }
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    closeTerminal: () => dispatch(closeTerminal())
});

export default connect(null, mapDispatchToProps)(VSCTerminal);
