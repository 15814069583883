import React from 'react';

const SourceControl = ({ className }) => (
    <svg viewBox="0 0 18.8 24.36" className={`source-control ${className}`}>
        <title>Source Control</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <circle cx="4.22" cy="3.9" r="3"/>
                <circle cx="14.9" cy="8.46" r="3"/>
                <circle cx="3.9" cy="20.46" r="3"/>
                <line x1="4.22" y1="7.4" x2="4.22" y2="17.4"/>
                <path d="M4.22,16.43a5.29,5.29,0,0,1,3.4-2.35c1.1-.23,2.24-.15,3.35-.27a9.32,9.32,0,0,0,3.38-1,1.56,1.56,0,0,0,.89-.89"/>
            </g>
        </g>
    </svg>
);

export default SourceControl;