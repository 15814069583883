import React from 'react';

const VSCBreadcrumbs = () => {
    return (
        <div className="vsc-breadcrumbs">
            
        </div>
    );
};

export default VSCBreadcrumbs;
