import React, { memo, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import useMeasure from '../hooks/useMeasure';
import usePrevious from '../hooks/usePrevious';
import Arrow from '../assets/Arrow';
import Close from '../assets/Close';
import * as FileIcons from '../assets/FileTypeIcons';

const Tree = memo(({ children, name, icon, openFile, closeFile, setActiveFile, activeFile, style, defaultOpen = false }) => {
    const [isOpen, setOpen] = useState(defaultOpen);
    const previous = usePrevious(isOpen);
    const [bind, { height: viewHeight }] = useMeasure();
    const { height, opacity, transform } = useSpring({
      from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
      to: { height: isOpen ? viewHeight : 0, opacity: isOpen ? 1 : 0, transform: `translate3d(${isOpen ? 0 : 20}px,0,0)` }
    });
    const rotate = useSpring({
      from: { transform: 'rotate(0deg)' },
      to: { transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)' }
    });
    let className, Icon;
    if (name === "OPEN EDITORS" || name === "JOHN MCDONALD") {
      className = 'tree__title-main';
    } else {
      if (name === activeFile) {
        className= 'tree__title active';
      } else {
        className = 'tree__title';
      }
      Icon = FileIcons[icon];
    }

    return (
      <div className="tree">
        <animated.div 
          className={className} 
          onClick={() => {
            if (openFile) {
              openFile(name, icon);
            } else if (setActiveFile) {
              setActiveFile(name);
            } else {
              setOpen(!isOpen)
            }
          }} 
        >
          { (name === "OPEN EDITORS" || name === "JOHN MCDONALD") && 
          <animated.div style={rotate}><Arrow className="tree__arrow" /></animated.div>}
          { closeFile && <div className="tree__icon-close" onClick={() => closeFile(name)}><Close /> </div>}
          { Icon && <Icon className="tree__icon-file" />}
          <span className="tree__title-text" style={style}>{name}</span>
        </animated.div>
        <animated.div 
          className="tree__content" 
          style={{ opacity, height: isOpen && previous === isOpen ? 'auto' : height }}
        >
          <animated.div style={{ transform }} {...bind} children={children} />
        </animated.div>
      </div>
    )
});

export default Tree;