import React from 'react';
import { connect } from 'react-redux';
import WelcomeFile from './WelcomeFile';
import ReadMeFile from './ReadMeFile';
import WebFile from './WebFile';
import NLPFile from './NLPFile';
import IoTFile from './IoTFile';
import DevOpsFile from './DevOpsFile';
import XRFile from './XRFile';

const VSCFile = ({ activeFile, fileSize }) => {
    const style = {
        maxHeight: `${fileSize}px`,
        overflow: 'auto',
        padding: '0 20px 100px 20px',
    };

    switch(activeFile) {
        case 'README.md':
            return (
                <div style={style} className="file">
                    <ReadMeFile />
                </div>
            )
        case 'Web.jsx': 
            return (
                <div style={style} className="file">
                    <WebFile />
                </div>
            );
        case 'NLP.js':
            return (
                <div style={style} className="file">
                    <NLPFile />
                </div>
            );
        case 'IoT.py':
            return (
                <div style={style} className="file">
                    <IoTFile />
                </div>
            );
        case 'DevOps.tf':
            return (
                <div style={style} className="file">
                    <DevOpsFile />
                </div>
            );
        case 'XR.cs':
            return (
                <div style={style} className="file">
                    <XRFile />
                </div>                
            );
        default:
            return (
                <div style={style} className="file">
                    <WelcomeFile />
                </div>
            );
    }
};

const mapStateToProps = ({ vsc }) => ({
    activeFile: vsc.activeFile,
    fileSize: vsc.fileSize
});

export default connect(mapStateToProps)(VSCFile);
