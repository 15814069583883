import React from 'react';

const Close = ({ className }) => (
    <svg viewBox="0 0 10 10" className={`close ${className}`}>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M4.36,4.81.35,0H1.51l2.1,2.54C4,3.09,4.5,3.68,5,4.22h.09c.47-.54,1-1.17,1.43-1.71L8.54,0H9.65L5.59,4.85,10,10H8.84L6.53,7.29C6,6.73,5.5,6,5,5.44h-.1c-.52.62-1,1.21-1.53,1.87L1.11,10H0Z"/>
            </g>
        </g>
    </svg>
);

export default Close;