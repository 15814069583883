import React from 'react';

const NLPFile = () => (
    <div className="nlp">
        <h1 className="nlp__title">Natural Language Processing (NLP)</h1>
        <div className="nlp__project">
            Produced an elderly care Alexa skill helping seniors better 
            manage living on their own while allowing their loved ones 
            to stay up to date on and help manage the senior’s activities 
            and well-being. 
        </div>
        <div className="nlp__project">
            Built a natural language search engine for matching a client’s 
            automotive products based on the make, model, and year of their 
            customers’ vehicles.
        </div>
        <div className="nlp__project">
            Created a digital travel assistant chatbot prototype for an 
            airline client using Dialogflow connected to an AWS backend and 
            external APIs, capable of managing travel, check-in, and flight status.
        </div>
        <div className="nlp__project">
            Developed a connected hospitality management platform utilizing a 
            customer-based voice interface which was featured at Mobile World 
            Congress.
        </div>
    </div>
);

export default NLPFile;