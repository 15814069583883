export const openFile = (file, icon) => ({
    type: 'OPEN_FILE',
    file,
    icon
});

export const closeFile = (file) => ({
    type: 'CLOSE_FILE',
    file
});

export const setActiveFile = (file) => ({
    type: 'SET_ACTIVE_FILE',
    file
});

export const reorderOpenFiles = (updatedIndices) => ({
    type: 'REORDER_OPEN_FILES',
    updatedIndices
});

export const toggleProjectSlider = () => ({
    type: 'TOGGLE_PROJECT_SLIDER'
});

export const openTerminal = () => ({
    type: 'OPEN_TERMINAL'
});

export const closeTerminal = () => ({
    type: 'CLOSE_TERMINAL'
});

export const setFileSize = (size) => ({
    type: 'SET_FILE_SIZE',
    size
});