import React from 'react';

const DevOpsFile = () => (
    <div className="devops">
        <h1 className="devops__title">DevOps</h1>
        <div className="devops__project">
            Created a command line interface (CLI) tool for scaffolding 
            web applications with a full continuous integration continuous 
            deployment (CI/CD) pipeline using Node.js, Terraform, and 
            GitLab CI. The pipeline definition deploys a production and 
            staging environment with ephemeral review app environments for 
            each feature branch that automatically get cleaned up after a 
            successful merge into master. 
        </div>
    </div>
);

export default DevOpsFile;