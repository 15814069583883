import React from 'react';

const WebFile = () => (
    <div className="web">
        <h1 className="web__title">Web</h1>
        <div className="web__project">
            Designed and developed an experimentation platform around 
            natural language processing in order to help clients quickly 
            implement chatbot-simulating solutions with real-time 
            operators and gather insights to better understand customer 
            intent before investing in full-scale development.
        </div>
        <div className="web__project">
            Architected and implemented an Alexa enabled Tableau dashboard 
            tool comprised of an Angular web application and a serverless 
            backend, including writing a custom algorithm in Python for 
            data anomaly detection. A generic version of this patent pending 
            asset was highlighted at the Tableau World Conference.
        </div>
        <div className="web__project">
            Created a serverless architecture design combined with a 
            Software as a Service (SaaS) model to connect a React web 
            application with a headless content management system, allowing 
            for customizable website generation during request for proposal 
            (RFP) processes.
        </div>
        <div className="web__project">
            Developed an iOS mobile application integrated with Bluetooth 
            beacons allowing factory workers to surface relevant documentation 
            based on a user’s proximity to and permissions for specific 
            machines and products.
        </div>
        <div className="web__project">
            Completed an experimentation platform for a major cellular company, 
            allowing for re-routing of customers from the client’s website to 
            multiple single page applications based on defined criteria for 
            A/B testing and a drip campaign implementation, without impacting 
            the client’s systems or the client team’s normal workflow. 
        </div>
        <div className="web__project">
            Constructed React starter templates allowing for varying 
            integrations with AWS, ranging from authentication and 
            authorization to real-time data transfer via GraphQL. 
            Templates without GraphQL were developed with an integrated 
            redux store, providing centralized state management and side-effect 
            handling using redux-saga middleware.
        </div>
    </div>
);

export default WebFile;