import React from 'react';
import { connect } from 'react-redux';
import Circle from '../assets/Circle';

const VSCHeader = ({ activeFile }) => {
    const displayText = activeFile === 'Welcome' ? 'John McDonald' : `John McDonald – ${activeFile}`;
    return (
        <header className="vsc-header">
            <div className="vsc-header__buttons" >
                <Circle className="circle-close" />
                <Circle className="circle-minimize" />
                <Circle className="circle-expand" />
            </div>
            <div className="vsc-header__text">{displayText}</div>
        </header>
    );
};

const mapStateToProps = ({ vsc }) => ({
    activeFile: vsc.activeFile
})

export default connect(mapStateToProps)(VSCHeader);
