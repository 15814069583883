import React from 'react';
import { connect } from 'react-redux';
import Tree from './Tree';
import { openFile, closeFile, setActiveFile } from '../actions/vsc';

const VSCProjectSlider = (props) => {
    const onOpenFile = (file, icon) => {
        props.openFile(file, icon);
    };

    const onCloseFile = (file) => {
        props.closeFile(file);
    };

    const onSetActiveFile = (file) => {
        props.setActiveFile(file);
    }

    return (
        <>
            <div className="vsc-project-slider__title">EXPLORER</div>
            <div className="vsc-project-slider__sliders">
                <Tree name="OPEN EDITORS" defaultOpen>
                    {props.openFiles.map(({file, icon}, index) => (
                        <Tree 
                            key={`${file}${index}`} 
                            name={file} 
                            icon={icon} 
                            closeFile={onCloseFile}
                            setActiveFile={onSetActiveFile}
                            activeFile={props.activeFile}
                        />
                    ))}
                </Tree>
                <Tree name="JOHN MCDONALD" defaultOpen>
                    {props.files.filter(({ file }) => (
                        file !== 'Welcome' 
                    )).map(({ file, icon }, index) => (
                        <Tree 
                            key={`${index}${file}`}  
                            name={file} 
                            icon={icon} 
                            openFile={onOpenFile}
                        />
                    ))}
                </Tree>
            </div>
        </>
    );
};

const mapStateToProps = ({ vsc }) => ({
    files: vsc.files,
    openFiles: vsc.openFiles,
    activeFile: vsc.activeFile
});

const mapDispatchToProps = (dispatch) => ({
    openFile: (file, icon) => dispatch(openFile(file, icon)),
    closeFile: (file) => dispatch(closeFile(file)),
    setActiveFile: (file) => dispatch(setActiveFile(file))
});

export default connect(mapStateToProps, mapDispatchToProps)(VSCProjectSlider);
