import React from 'react';

const XRFile = () => (
    <div className="xr">
        <h1 className="xr__title">Extended Reality (XR)</h1>
        <div className="xr__project">
            Built a connected worker experience on Unity and AWS, 
            utilizing DAQRI Smart Glasses to provide field workers with 
            supplemental information and instructional animations overlaid 
            in the real world using augmented reality.
        </div>
        <div className="xr__project">
            3D-modeled a luggage rack with Blender and embedded the model 
            within an augmented reality iOS application using ARkit to allow 
            an airline’s customers to validate their bags’ size compliance from 
            anywhere.
        </div>
        <div className="xr__project">
            Created an augmented reality application with Unity and Vuforia 
            allowing customers to visualize products in their physical environment 
            to increase conversion rates.
        </div>
    </div>
);

export default XRFile;