import React from 'react';
import { connect } from 'react-redux';
import VSCAppPanel from '../containers/VSCAppPanel';
import VSCBreadcrumbs from '../containers/VSCBreadcrumbs';
import VSCFile from '../containers/VSCFile';
import VSCFileTab from '../containers/VSCFileTab';
import VSCFooter from '../containers/VSCFooter';
import VSCHeader from '../containers/VSCHeader';
import VSCProjectSlider from '../containers/VSCProjectSlider';
import VSCTerminal from '../containers/VSCTerminal';
import SplitPane, { Pane1, Pane2 } from '../containers/SplitPane';

const VisualStudioCode = (props) => {
    const isMobile = window.innerWidth <= 420;

    if (isMobile) {
        return (
            <div className="vsc-mobile">This site is not yet optimized for mobile browsing</div>
        );
    }
    return (
        <div className="vsc">
            <VSCHeader />
            <VSCAppPanel />
            <div className="resize">
                <SplitPane split="vertical" opened={props.projectSliderOpened} defaultSize={200}>
                    <Pane1 className="vsc-project-slider">
                        <VSCProjectSlider />
                    </Pane1>
                    <Pane2>
                        <SplitPane split="horizontal" opened={props.terminalOpened} defaultSize={600}>
                            <Pane1 className="vsc-file">
                                <VSCFileTab />
                                <VSCBreadcrumbs />
                                <VSCFile />
                            </Pane1>
                            <Pane2 className="vsc-terminal">
                                <VSCTerminal />
                            </Pane2>
                        </SplitPane>
                    </Pane2>
                </SplitPane>
            </div>
            <VSCFooter />
        </div>
    );
};

const mapStateToProps = ({ vsc }) => ({
    projectSliderOpened: vsc.projectSliderOpened,
    terminalOpened: vsc.terminalOpened
});

export default connect(mapStateToProps)(VisualStudioCode);
