import React from 'react';
import { connect } from 'react-redux';
import Search from '../assets/Search';
import Debugger from '../assets/Debugger';
import Explorer from '../assets/Explorer';
import Extensions from '../assets/Extensions';
import Settings from '../assets/Settings';
import SourceControl from '../assets/SourceControl';
import { toggleProjectSlider } from '../actions/vsc';

const VSCAppPanel = (props) => {
    return (
        <div className="vsc-app-panel">
            <div onClick={()=> props.toggleProjectSlider()}className="vsc-app-panel__menu">
                <Explorer />
                <Search />
                <SourceControl />
                <Debugger />
                <Extensions />
            </div>
            <div className="vsc-app-panel__settings">
                <Settings />
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    toggleProjectSlider: () => dispatch(toggleProjectSlider())
});

export default connect(null, mapDispatchToProps)(VSCAppPanel);
