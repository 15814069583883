import React from 'react';
import DraggableList from './DraggableList';
import { connect } from 'react-redux';
import { closeFile, setActiveFile, reorderOpenFiles } from '../actions/vsc';

const VSCTabBar = (props) => {
    const onCloseFile = (file) => {
        props.closeFile(file);
    };

    const onSetActiveFile = (file) => {
        props.setActiveFile(file);
    }

    return (
        <div className="vsc-file-tab">
            <DraggableList 
                key={props.openFiles}
                setActiveFile={onSetActiveFile} 
                closeFile={onCloseFile}
                reorderFiles={props.reorderOpenFiles} 
                items={props.openFiles} 
                activeFile={props.activeFile}
            />
        </div>
    );
};

const mapStateToProps = ({ vsc }) => ({
    openFiles: vsc.openFiles,
    activeFile: vsc.activeFile
});

const mapDispatchToProps = (dispatch) => ({
    closeFile: (file) => dispatch(closeFile(file)),
    setActiveFile: (file) => dispatch(setActiveFile(file)),
    reorderOpenFiles: (indices) => dispatch(reorderOpenFiles(indices))
});

export default connect(mapStateToProps, mapDispatchToProps)(VSCTabBar);
