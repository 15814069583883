import React from 'react';

const Search = ({ className }) => (
    <svg viewBox="0 0 23.27 23.77" className={`search ${className}`}>
        <title>Search</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <circle cx="14.87" cy="8.4" r="7.5"/>
                <line x1="10.1" y1="13.67" x2="0.64" y2="23.13"/>
            </g>
        </g>
    </svg>
);

export default Search;