import React from 'react';

const Debugger = ({ className }) => (
    <svg viewBox="0 0 25.47 21.89" className={`debugger ${className}`}>
        <title>Debugger and Run</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <line x1="1.5" y1="1.85" x2="5.5" y2="5.85"/>
                <line y1="11.75" x2="5" y2="11.75"/>
                <line x1="6.5" y1="17.25" x2="2.5" y2="21.25"/>
                <line x1="22.97" y1="1.85" x2="18.97" y2="5.85"/>
                <line x1="20.47" y1="11.85" x2="25.47" y2="11.85"/>
                <line x1="18.97" y1="17.25" x2="22.97" y2="21.25"/>
                <path d="M17,5.75h2a.08.08,0,0,1,0,0A13.62,13.62,0,0,1,20,14c-.79,3-3.42,6.55-7,6.72a7.32,7.32,0,0,1-5-2,9.54,9.54,0,0,1-3-6,12.17,12.17,0,0,1,1-7H17Z"/>
                <path d="M17,5.25a4.07,4.07,0,0,1,0,.5H8a4.07,4.07,0,0,1,0-.5,4.5,4.5,0,0,1,9,0Z"/>
                <line x1="9.43" y1="9.25" x2="16.5" y2="16.25"/>
                <line x1="16.04" y1="9.25" x2="8.97" y2="16.25"/>
            </g>
        </g>
    </svg>
);

export default Debugger;