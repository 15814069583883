import React, {
    useState,
    useContext,
    createContext,
    useRef,
    createRef,
    useEffect
} from "react";
import { connect } from 'react-redux';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { setFileSize } from '../actions/vsc';
  
const splitPaneContext = createContext();

const SplitPane = ({ children, split = 'vertical', defaultSize, maxHeight, maxWidth, opened, setFileSize, ...props }) => {
    const { height, width } = useWindowDimensions();
    const [paneSize, setpaneSize] = useState(null);
    const separatorPosition = useRef(null);
    const splitPaneRef = createRef();
    maxHeight = maxHeight <= height - 44 ? maxHeight : height - 200;
    maxWidth = maxWidth <= width - 44 ? maxWidth : width - 50;

    const onMouseDown = e => {
        separatorPosition.current = split === "horizontal" ? e.clientY : e.clientX;
    };

    const onMouseMove = e => {
        if (separatorPosition.current) {
            let newpaneSize;
            if (split === "horizontal") {
                newpaneSize = paneSize + e.clientY - separatorPosition.current;
                separatorPosition.current = e.clientY;
            } else {
                newpaneSize = paneSize + e.clientX - separatorPosition.current;
                separatorPosition.current = e.clientX;
            }
            // TODO set min and max height/width
            if ((split === "horizontal" && newpaneSize <= maxHeight) || (split === "vertical" && newpaneSize <= maxWidth)) {
                setpaneSize(newpaneSize);
                if (split === 'horizontal') {
                    setFileSize(newpaneSize);
                }
            }
        }
    };

    const onMouseUp = () => {
        separatorPosition.current = null;
    };

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);

        return () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", onMouseUp);
        };
    });

    return (
        <div {...props} className={`split-pane ${split}`} ref={splitPaneRef}>
        <splitPaneContext.Provider
            value={{ paneSize, setpaneSize, split, defaultSize, opened, height }}
        >
            {children[0]}
            <div className={`separator ${split}`} onMouseDown={onMouseDown} />
            {children[1]}
        </splitPaneContext.Provider>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setFileSize: (size) => dispatch(setFileSize(size))
});

export default connect(null, mapDispatchToProps)(SplitPane);

const Pane1 = props => {
    const paneRef = createRef();
    const { paneSize, setpaneSize, split, defaultSize, opened, height } = useContext(
        splitPaneContext
    );
    const className = props.className || "";

    useEffect(() => {
        if (opened) {
            if (!paneSize) {
                if (split === "horizontal") {
                    setpaneSize(defaultSize);
                } else {
                    setpaneSize(defaultSize);
                }
                paneRef.current.style.flex = "none";
                return;
            }
        } else {
            if (split !== "horizontal") {
                setpaneSize(0);
                paneRef.current.style.flex = "none";
            } else {
                setpaneSize(height - 72);
            }
        }

        if (split === "horizontal") {
            paneRef.current.style.height = `${paneSize}px`;
        } else {
            paneRef.current.style.width = `${paneSize}px`;
        }
    }, [paneSize, paneRef, setpaneSize, split, defaultSize, opened, height]);

    return <div {...props} className={`pane1 ${className}`} ref={paneRef} />;
};

const Pane2 = props => {
    const className = props.className || "";
    return <div {...props} className={`pane2 ${className}`} />;
};

export { Pane1, Pane2 };
