import React from 'react';

const Settings = ({ className }) => (
    <svg viewBox="0 0 22.81 22.81" className={`settings ${className}`}>
        <title>Settings</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M12.92.9l.2,3.25,2.21.91,3.22-2.95L20.7,4.26,17.75,7.49l.92,2.2,3.24.2v3l-3.24.2-.92,2.21,2.95,3.22L18.55,20.7l-3.22-2.95-2.21.92-.2,3.24h-3l-.2-3.24-2.2-.92L4.26,20.7,2.11,18.55l2.95-3.22-.91-2.21L.9,12.92v-3l3.25-.2.91-2.2L2.11,4.26,4.26,2.11,7.49,5.06l2.2-.91L9.89.9Z"/>
                <circle cx="11.41" cy="11.41" r="2.71"/>
            </g>
        </g>
    </svg>
);

export default Settings;