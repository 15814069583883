import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import VisualStudioCode from '../pages/VisualStudioCode';
import history from '../history';

const AppRouter = () => (
    <Router history={history}>
        <Switch>
            <Route path="/" component={VisualStudioCode} exact/>
        </Switch>
    </Router>
);

export default AppRouter;